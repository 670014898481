import React from 'react';
import ReactDOM from 'react-dom';
import WarzoneStats from './main/typescript/WarzoneStats';

ReactDOM.render(
    <React.StrictMode>
        <WarzoneStats />
    </React.StrictMode>,
    document.getElementById('root'),
);
