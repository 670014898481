import React from 'react';
import { formatUtcShortDateDay } from '../../../util/dateUtils';

interface MapNameProps {
    mapName: string;
    matchStart: number;
}

const MapName: React.FC<MapNameProps> = (props: MapNameProps) => {
    return (
        <div className="matchTable-mapNameDiv">
            <div>
                <span className="matchTableMapTime sectionText ssmallerText">{formatUtcShortDateDay(props.matchStart)}</span>
                {/* <span className="matchTableMapName sectionText smallerText">{props.mapName}</span> */}
            </div>
        </div>
    );
};

export default MapName;
