import React, { FC, ReactElement } from 'react';

interface GeneralErrorProps {
    errorId: string;
}

export const GeneralErrorPage: FC<GeneralErrorProps> = (props: GeneralErrorProps): ReactElement => {
    const { errorId } = props;

    return (
        <div className="text-center">
            <div className="mt--u-9 mb--u-9">
                <img src="/images/dont-cry.jpg" alt="" className="img-responsive inline-block" />
            </div>
            <h1 className="mb--u-7">Jejda, něco se pokazilo!</h1>
            <p className="h2">{`Na přehledu se vyskytla chyba: ${errorId}`}</p>
        </div>
    );
};
