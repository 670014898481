import React from 'react';

interface MapImageProps {
    mapName: string;
    matchId: string;
}

const MapImage: React.FC<MapImageProps> = (props: MapImageProps) => {
    return (
        <div className="matchTable-mapImageDiv">
            <div>
                <img className="matchTableMapImage shadowFilter" src={`/images/warzone-maps/${props.mapName}.webp`} alt="map" />
                <span className="matchTableMapImageDetailLink">
                    <a href={`/detail/${props.matchId}`}>
                        <img className="shadowFilter" src={`/images/link-icon.png`} alt="link icon" />
                    </a>
                </span>
            </div>
        </div>
    );
};

export default MapImage;
