import 'dotenv/config';
import React from 'react';
import './WarzoneStats.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MatchList from './match-history/MatchList';
import { ErrorBoundary } from './error/ErrorBoundary';
import MatchInfoDetail from './match-history/MatchInfoDetail';

const WarzoneStats: React.FC = () => {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MatchList />} />
                    <Route path="/detail/:matchId" element={<MatchInfoDetail />} />
                </Routes>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default WarzoneStats;
