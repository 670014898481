import React from 'react';
import MapName from './components/MapName';
import MapImage from './components/MapImage';
import ModeImageName from './components/ModeImageName';
import TeamNamePlacement from './components/TeamNamePlacement';
import PlayersScore from './components/PlayersScore';
import { MatchDetail } from '../../rest/match/dto/MatchDetail';
import { findOurTeam, processTeams } from './matchTableUtils';

interface MatchTableProps {
    matchDetail: MatchDetail;
    verboseTeams: boolean;
    verboseOurTeam: boolean;
    verboseBigTeam: boolean;
    onlyFirstPlaces: boolean;
}

const MatchTable: React.FC<MatchTableProps> = (props: MatchTableProps) => {
    const { matchDetail, verboseTeams, verboseOurTeam, verboseBigTeam, onlyFirstPlaces } = props;
    const { map, matchStart, mode, teams } = matchDetail;

    console.log(`processing match: ${matchDetail.matchId}`);

    const teamDetails = processTeams(teams, verboseTeams);

    const ourTeam = findOurTeam(teams);

    const showIt = (): boolean => {
        return !onlyFirstPlaces || ourTeam.teamPlacement === 1;
    };

    const renderMatchTable = () => {
        return (
            <div className="matchTableContainer">
                <MapName mapName={map} matchStart={matchStart} />
                <MapImage mapName={map} matchId={matchDetail.matchId} />
                <ModeImageName mode={mode} matchId={matchDetail.matchId} />
                <TeamNamePlacement matchId={matchDetail.matchId} ourTeam={ourTeam} />
                <PlayersScore teamDetails={teamDetails} verboseOurTeam={verboseOurTeam} verboseTeams={verboseTeams} verboseBigTeam={verboseBigTeam} />
            </div>
        );
    };

    return showIt() ? renderMatchTable() : null;
};

export default MatchTable;
