import { getRequest } from '../RestHelper';
import { MatchHistory } from './dto/MatchHistory';
import { MatchDetail } from './dto/MatchDetail';

const getMatchHistoryParams = (modes?: string[], minKnownPlayersCount?: number, minPlacement?: number, limit?: number, matchIds?: number[]): string => {
    const modesParam = modes ? `modes=${modes.join(',')}` : '';
    const minKnownPlayersCountParam = minKnownPlayersCount ? `minKnownPlayersCount=${minKnownPlayersCount}` : '';
    const minPlacementParam = minPlacement ? `minPlacement=${minPlacement}` : '';
    const limitParam = limit ? `limit=${limit}` : '';
    const matchIdsParam = matchIds ? `matchIds=${matchIds.join(',')}` : '';

    return `${modesParam}&${minKnownPlayersCountParam}&${minPlacementParam}&${limitParam}&${matchIdsParam}`;
};

/**
 * e. g. http://acheron.sk:3007/stats/api/cod/match?modes=br_dbd_iron_trials_duos&minKnownPlayersCount=1
 * @param modes filter given modes, if no modes given, all modes are fetched
 * @param minKnownPlayersCount return only matches with at least minKnownPlayersCount known players
 * @param minPlacement optional filter, return only matches with given placement and higher (minPlacement=3 -> return medals)
 * @param limit limit returned matched
 * @param matchIds optional parameter for selecting specific matches
 */
export const loadMatchHistory = async (
    modes?: string[],
    minKnownPlayersCount?: number,
    minPlacement?: number,
    limit?: number,
    matchIds?: number[],
): Promise<MatchHistory> => {
    const params = getMatchHistoryParams(modes, minKnownPlayersCount, minPlacement, limit || 20, matchIds);
    const url = `${process.env.REACT_APP_MATCH_HISTORY_REST_URL}/match?${params}`;
    console.log(url);
    const result: Promise<unknown> = getRequest(url);
    return result as Promise<MatchHistory>;
};

/**
 * e. g. http://localhost:3007/stats/api/cod/match/detail/11007653659406457253
 * @param matchId id of match to get detail for
 */
export const loadMatchDetail = async (matchId: string): Promise<MatchDetail> => {
    const url = `${process.env.REACT_APP_MATCH_HISTORY_REST_URL}/match/detail/${matchId}`;
    console.log(url);
    const result: Promise<unknown> = getRequest(url);
    return result as Promise<MatchDetail>;
};
