import React from 'react';
import { MODE_SHOWN_NAME_AND_ICON } from '../../../util/matchConstants';

interface ModeImageNameProps {
    mode: string;
    matchId: string;
}

const getModeNameAndIcon = (mode: string): string[] => {
    const modeImage = MODE_SHOWN_NAME_AND_ICON.get(mode);

    return modeImage || [mode, 'unknown'];
};

const ModeImageName: React.FC<ModeImageNameProps> = (props: ModeImageNameProps) => {
    const [modeName, modeImageName] = getModeNameAndIcon(props.mode);

    return (
        <div className="matchTable-modeImageNameDiv">
            <div>
                <img className="matchTableModeImage shadowFilter" src={`/images/warzone-modes/${modeImageName}.webp`} alt="mode name" />
                <span className="matchTableModeName sectionText">{modeName}</span>
            </div>
        </div>
    );
};

export default ModeImageName;
