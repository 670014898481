import React from 'react';
import { TeamDetail } from '../../../rest/match/dto/TeamDetail';
import { PlayerDetail } from '../../../rest/match/dto/PlayerDetail';
import { teamContainsKnownPlayers } from '../matchTableUtils';
import { KNOWN_PLAYERS } from '../../../util/constants';

interface PlayersScoreProps {
    teamDetails: TeamDetail[];
    verboseTeams: boolean;
    verboseOurTeam: boolean;
    verboseBigTeam: boolean;
}

const PlayersScore: React.FC<PlayersScoreProps> = (props) => {
    const { teamDetails, verboseTeams } = props;

    const renderPlayerDetailHeader = () => {
        return (
            <tr className="sectionText smallerText verticalTrGap">
                <th>player</th>
                <th>score</th>
                <th>kills</th>
                <th>dths</th>
                <th>K/D</th>
            </tr>
        );
    };

    const renderPlayerDetailRow = (playerDetail: PlayerDetail) => {
        const boldPlayerName = KNOWN_PLAYERS.indexOf(playerDetail.username) > -1 ? 'matchPlayersPlayerBold' : '';
        return (
            <tr className="sectionText" key={playerDetail.username}>
                <td className={`matchPlayersPlayer ${boldPlayerName}`}>{playerDetail.username}</td>
                <td className="matchPlayersScore">{playerDetail.playerStats.score}</td>
                <td className="matchPlayersKills">{playerDetail.playerStats.kills}</td>
                <td className="matchPlayersDeaths">{playerDetail.playerStats.deaths}</td>
                <td className="matchPlayersKdratio">{Math.round((playerDetail.playerStats.kdRatio + Number.EPSILON) * 100) / 100}</td>
            </tr>
        );
    };

    const renderTeamRows = (teamDetail: TeamDetail) => {
        let showOtherPlayersInTeam = props.verboseOurTeam || props.verboseTeams;
        if (teamDetail.playersCount > 5) {
            showOtherPlayersInTeam = showOtherPlayersInTeam && props.verboseBigTeam;
        }

        // console.log(`rendering team rows: ${teamDetail.players.length} players - ${showOtherPlayersInTeam}`);

        if (teamContainsKnownPlayers(teamDetail) || showOtherPlayersInTeam) {
            return teamDetail.players
                .filter((playerDetail) => showOtherPlayersInTeam || KNOWN_PLAYERS.indexOf(playerDetail.username) > -1)
                .map((playerDetail) => renderPlayerDetailRow(playerDetail));
        }

        return <div></div>;
    };

    const renderTeamTable = (teamDetail: TeamDetail) => {
        return (
            <div className="matchPlayersTeamDivScoreTable">
                <table className="matchPlayers">
                    <thead>{renderPlayerDetailHeader()}</thead>
                    <tbody>{renderTeamRows(teamDetail)}</tbody>
                </table>
            </div>
        );
    };

    const renderTeamDiv = (teamDetail: TeamDetail) => {
        if (teamDetail === undefined) {
            return null;
        }

        return (
            <div className="matchPlayersTeamDiv" key={teamDetail.name}>
                <div className="matchPlayersTeamDivHeader sectionText">
                    #{teamDetail.teamPlacement}&nbsp;&nbsp;&nbsp;&nbsp;
                    {teamDetail.name}&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="teamPlacement">{teamDetail.score}</span>
                </div>
                {teamContainsKnownPlayers(teamDetail) || verboseTeams ? renderTeamTable(teamDetail) : null}
            </div>
        );
    };

    return <div className="matchTable-playerScoreDiv">{teamDetails.map((teamDetail) => renderTeamDiv(teamDetail))}</div>;
};

export default PlayersScore;
