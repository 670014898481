/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MatchTable from './match-table/MatchTable';
import { loadMatchDetail } from '../rest/match/MatchHistoryRest';
import { loadAndSetData } from '../util/dataLoader';
import { useAsyncError } from '../error/ErrorBoundary';
import { MatchDetail } from '../rest/match/dto/MatchDetail';

const MatchInfoDetail: React.FC = () => {
    const { matchId } = useParams();

    console.log(`Using matchId: ${matchId}`);

    const [matchDetail, setMatchDetail] = useState<MatchDetail>();

    const throwError = useAsyncError();

    const loadMatchDetailById = (): Promise<MatchDetail> => {
        console.log(`loading match by id: ${matchId}`);
        return loadMatchDetail(matchId || '1');
    };

    useEffect(() => {
        let isMounted = true;

        // load data required by widgets into state variables in this side effect
        loadAndSetData(loadMatchDetailById, setMatchDetail, throwError, isMounted).then(() => {});

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div>
            {matchDetail ? (
                <MatchTable matchDetail={matchDetail} verboseOurTeam={true} verboseTeams={true} verboseBigTeam={true} onlyFirstPlaces={false} />
            ) : null}
        </div>
    );
};

export default MatchInfoDetail;
