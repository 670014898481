export const MODE_SHOWN_NAME_AND_ICON: Map<string, Array<string>> = new Map([
    ['war', ['Team DM', 'mw_mp_team_deathmatch', 'deathmatch']],
    ['arm', ['Ground War', 'mw_mp_ground_war', 'war']],
    ['cyber', ['Cyber Attack Pro', 'cyber_attack', 'deathmatch']],

    ['br_payload_payload', ['Payload', 'mw_wz_payload', 'war']],

    ['br_payload_playlist_wz325/rbrthpayload', ['Rebirth Payload', 'mw_wz_payload', 'war']],
    ['br_mendota_playlist_wz330/op_mon', ['Godzilla', 'monarch', 'resurgence']],
    ['br_dmz_playlist_wz325/rbrthbmo_quads', ['Blood Money 4', 'blood_money', 'resurgence']],
    ['br_gold_war_playlist_wz340/gld_pldr', ['Golden Plunder', 'golden_plunder', 'resurgence']],

    ['hq', ['Headquarters', 'mw_mp_headquarters', 'deathmatch']],
    ['br_kingslayer_kingsltrios', ['Kingslayer', 'mw_wz_king_slayer', 'resurgence']],
    ['br_br_solo', ['Battle Royale SOLO', 'br_quads', 'battleroyale']],
    ['br_br_duos', ['Battle Royale Duos', 'br_quads', 'battleroyale']],
    ['br_br_trios', ['Battle Royale Trios', 'br_quads', 'battleroyale']],
    ['br_br_quads', ['Battle Royale Quads', 'br_quads', 'battleroyale']],
    ['br_brsolo', ['Battle Royale SOLO', 'br_quads', 'battleroyale']],
    ['br_brduos', ['Battle Royale Duos', 'br_quads', 'battleroyale']],
    ['br_brtrios', ['Battle Royale Trios', 'br_quads', 'battleroyale']],
    ['br_brquads', ['Battle Royale Quads', 'br_quads', 'battleroyale']],
    ['br_respect_playlist_wz335/respect', ['Caldera Champion', 'caldera_champion', 'battleroyale']],
    ['br_respect_playlist_wz345/respect_trios', ['Caldera Champion Trios', 'caldera_champion', 'battleroyale']],
    ['br_vg_royale_solo', ['Vanguard Royale SOLO', 'wz_vanguard_royale', 'battleroyale']],
    ['br_vg_royale_duos', ['Vanguard Royale Duos', 'wz_vanguard_royale', 'battleroyale']],
    ['br_vg_royale_trios', ['Vanguard Royale Trios', 'wz_vanguard_royale', 'battleroyale']],
    ['br_vg_royale_quads', ['Vanguard Royale Quads', 'wz_vanguard_royale', 'battleroyale']],
    ['br_rebirth_cal_res_royale', ['Vanguard Rebirth', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_rebirth_calderaresurgence', ['Caldera Rebirth', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_rebirth_cal_res_trios', ['Caldera Rebirth', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_rebirth_rbrthquad', ['Rebirth Quads', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_rebirth_rbrthduos', ['Rebirth Duos', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_rebirth_rbrthtrios', ['Rebirth Trios', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_playlist_wz335/rebirthexfilttrios', ['Rebirth Exfiltration', 'rebirth_exfiltration', 'resurgence']],
    ['br_mini_rebirth_mini_royale_duos', ['Mini Royale', 'wz_vanguard_royale']],
    ['br_rebirth_reverse_playlist_wz325/rbrthsolos', ['Rebirth Solo', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_rebirth_playlist_wz325/afd_resurgence', ['Totally Normal Rebirth', 'wz_vanguard_resurgence', 'resurgence']],
    ['br_playlist_wz325/br_aprl_fool_name4', ['Totally Normal BR', 'br_quads', 'battleroyale']],

    ['br_dbd_playlist_wz320/rbrthdbd_quads', ['Iron Trials Quads', 'titanium_trials', 'battleroyale']],
    ['br_mmp_playlist_wz350/mmp', ['Stick and stones', 'stick_and_stones', 'battleroyale']],

    ['br_rebirth_reverse_playlist_wz340/fortkeep_res_solo', ["Fortune's Keep Solos", 'fortunes_keep', 'resurgence']],
    ['br_rebirth_playlist_wz340/fortkeep_res_duos', ["Fortune's Keep Duos", 'fortunes_keep', 'resurgence']],
    ['br_rebirth_playlist_wz340/fortkeep_res_trios', ["Fortune's Keep Trios", 'fortunes_keep', 'resurgence']],
    ['br_rebirth_playlist_wz340/fortkeep_res_quad', ["Fortune's Keep Quads", 'fortunes_keep', 'resurgence']],
    ['br_rebirth_playlist_wz340/fortkeep_extreme', ["Fortune's Keep Extreme", 'fortunes_keep', 'resurgence']],
    ['br_zxp_playlist_wz345/rxp', ['Rebirth of The Dead', 'rebirth_of_the_dead', 'resurgence']],

    ['br_dmz_vg_pln_trios', ['Vanguard Plunder', 'wz_vanguard_royale', 'battleroyale']],
    ['br_buy_back_duos', ['Buy Back Duos', 'br_buy_back', 'battleroyale']],
    ['br_buy_back_trios', ['Buy Back Trios', 'br_buy_back', 'battleroyale']],
    ['br_buy_back_quads', ['Buy Back Quads', 'br_buy_back', 'battleroyale']],
    ['br_rumble_clash', ['Clash', 'caldera_clash', 'war']],
    ['br_rumble_clash_caldera', ['Caldera Clash', 'caldera_clash', 'war']],
    ['br_rumble_playlist_wz340/storage_town_clash_title', ['Storage Town Clash', 'caldera_clash', 'war']],
    ['br_olaride_playlist_wz350/olaride', ['Operation: Last Call', 'caldera_last_call', 'resurgence']],
    ['br_tdbd_playlist_wz345/cal_titanium_quads', ['Titanium Trilas: Endurance', 'titanium_trials', 'battleroyale']],
]);
