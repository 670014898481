import { TeamDetail } from '../../rest/match/dto/TeamDetail';
import { KNOWN_PLAYERS } from '../../util/constants';

const findFirstTeam = (teams: TeamDetail[]): TeamDetail => {
    if (teams.length === 1) {
        return teams[0];
    }

    return teams.filter((team) => {
        if (team.teamPlacement) {
            return team.teamPlacement === 1;
        }
        return team.result === 'win';
    })[0];
};

export const teamContainsKnownPlayers = (team: TeamDetail): boolean => {
    const knownPlayers = team.players.filter((playerDetail) => KNOWN_PLAYERS.indexOf(playerDetail.username) > -1);
    // console.log(`Checking team ${team.name}: known players: ${knownPlayers.length}`);
    return knownPlayers.length > 0;
};

export const findOurTeam = (teams: TeamDetail[]): TeamDetail => {
    return teams.filter((team) => teamContainsKnownPlayers(team))[0];
};

const teamPlacementComparator = (teamA: TeamDetail, teamB: TeamDetail): number => {
    if (teamA.teamPlacement < teamB.teamPlacement) {
        return -1;
    }
    if (teamA.teamPlacement > teamB.teamPlacement) {
        return 1;
    }
    return 0;
};

const findLastTeam = (teams: TeamDetail[]): TeamDetail => {
    if (teams.length === 1) {
        return teams[0];
    }

    teams.sort(teamPlacementComparator);
    // const teamsPlacementMap = teams.map((teamDetal): [number, TeamDetail] => [teamDetal.teamPlacement, teamDetal]);
    return teams[teams.length - 1];
};

const containsTeam = (teams: TeamDetail[], team: TeamDetail): boolean => {
    if (teams.length === 0) {
        return false;
    }

    return teams.some((teamDetail) => {
        if (teamDetail === undefined) {
            return false;
        }
        return teamDetail.name === team.name;
    });
};

const sortPlayersInTeam = (team: TeamDetail): void => {
    team.players.sort((playerA, playerB) => {
        const scoreA = playerA.playerStats.score;
        const scoreB = playerB.playerStats.score;

        if (scoreA > scoreB) {
            return -1;
        }
        if (scoreB > scoreA) {
            return 1;
        }
        return 0;
    });
};

export const processTeams = (teams: TeamDetail[], verboseTeams: boolean): TeamDetail[] => {
    let filteredTeams: TeamDetail[] = [];

    if (!verboseTeams) {
        const firstTeam = findFirstTeam(teams);
        const ourTeam = findOurTeam(teams);
        sortPlayersInTeam(ourTeam);
        const lastTeam = findLastTeam(teams);

        if (!containsTeam(filteredTeams, firstTeam)) {
            filteredTeams.push(firstTeam);
        }
        if (!containsTeam(filteredTeams, ourTeam)) {
            filteredTeams.push(ourTeam);
        }
        if (!containsTeam(filteredTeams, lastTeam)) {
            filteredTeams.push(lastTeam);
        }
    } else {
        filteredTeams = teams;
        filteredTeams.forEach((teamDetail) => sortPlayersInTeam(teamDetail));
        filteredTeams.sort(teamPlacementComparator);
    }

    return filteredTeams;
};
