import axios from 'axios';

/**
 * Create request options object to be sent with axios requests
 * If SID cookie is found in local storage, glue it to request to allow auth to teamio-gate (only for local development)
 * If you ever find G2_SID cookie in local storage on prod, scream.
 */
const requestOptions = (): Record<string, unknown> => {
    const sidCookie = window.localStorage.getItem('g2SidCookie');
    return sidCookie ? { withCredentials: true, headers: { 'X-Authorization': `Bearer ${sidCookie};` } } : {};
};

/**
 * Function fetching data from GET API endpoint
 * @param url URL to request
 * @return promise with loaded data
 */
export const getRequest = async (url: string): Promise<Record<string, undefined>> => {
    let response = null;
    try {
        response = await axios.get(url, requestOptions());
    } catch (error) {
        const message = `Failed to fetch response from "${url}", error: ${(error as Error).message}`;
        // throw vs promise
        // https://stackoverflow.com/questions/33445415/javascript-promises-reject-vs-throw
        return Promise.reject(new Error(message));
    }
    const responseOK = response && response.status === 200;
    if (responseOK) {
        return response.data;
    }

    // although rest fetch did finish and server API responded, data is invalid (e. g. Internal server error)
    const message = `Failed to fetch response from "${url}", status = ${response.status}, statusText = ${response.statusText}`;
    return Promise.reject(new Error(message));
};

export const postRequest = async (url: string, data: Record<string, undefined>): Promise<boolean> => {
    let response = null;
    try {
        response = await axios.post(url, data, requestOptions());
    } catch (error) {
        const message = `Failed to POST request to "${url}", error: ${(error as Error).message}`;
        return Promise.reject(new Error(message));
    }
    if (!response || response.status !== 200) {
        const message = `Failed to post "${url}", status = ${response.status}, statusText = ${response.statusText}`;
        return Promise.reject(new Error(message));
    }
    return Promise.resolve(true);
};

/**
 * Do a simple post request to logging api
 * @param url url to post data to
 * @param data data to post
 */
export const loggingApiPostRequest = async (url: string, data: Record<string, undefined>): Promise<Record<string, unknown>> => {
    try {
        return await axios.post(url, data, requestOptions());
    } catch (error) {
        const message = `Failed to POST request to "${url}", error: ${(error as Error).message}`;
        console.log(message);

        return Promise.reject(new Error(message));
    }
};
