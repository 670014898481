const FULL_DATE_TIME_FORMAT: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
};

const SHORT_DATE_TIME_FORMAT: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
};

const SHORT_DATE_DAY_TIME_FORMAT: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    weekday: 'short',
    hour: '2-digit',
    minute: '2-digit',
};

export function formatDate(date: Date): string {
    return date.toLocaleDateString('cs-CZ', {
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });
}

export function formatUtcShortDate(utc: number): string {
    const date = new Date(0);
    date.setUTCSeconds(utc);

    return date.toLocaleDateString('cs-CZ', SHORT_DATE_TIME_FORMAT);
}

export function formatUtcShortDateDay(utc: number): string {
    const date = new Date(0);
    date.setUTCSeconds(utc);

    return date.toLocaleDateString('sk-SK', SHORT_DATE_DAY_TIME_FORMAT);
}

export function formatUtcFullDate(utc: number): string {
    const date = new Date(0);
    date.setUTCSeconds(utc);

    return date.toLocaleDateString('cs-CZ', FULL_DATE_TIME_FORMAT);
}
