/**
 * Function loads data via rest and uses provided setter to set the data into state
 * @param loader async data loader used to fetch data
 * @param setData setter to call with fetched data
 * @param isMounted only set data if component is mounted
 * @return empty Promise
 */
import { ErrorCallbackType } from '../error/ErrorBoundary';

export const loadAndSetData = async <T>(
    loader: () => Promise<T>,
    setData: (setter: T) => void,
    throwError: ErrorCallbackType,
    isMounted: boolean,
): Promise<T> => {
    try {
        if (isMounted) {
            const data = await loader();
            setData(data);
        }
    } catch (e) {
        console.log(e);
        throwError(e as Error);
    }

    return null as unknown as Promise<T>;
};
