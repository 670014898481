import React from 'react';
import { TeamDetail } from '../../../rest/match/dto/TeamDetail';

interface TeamNamePlacementProps {
    matchId: string;
    ourTeam: TeamDetail;
}

const TeamNamePlacement: React.FC<TeamNamePlacementProps> = (props) => {
    const { ourTeam, matchId } = props;

    const { name, teamPlacement } = ourTeam;

    const winText = ourTeam.teamPlacement === 1 ? 'WON' : 'LOST';
    const winClass = ourTeam.teamPlacement === 1 ? 'teamPlacementGreen' : 'teamPlacementRed';

    return (
        <div className={`matchTable-teamNamePlacementDiv ${winClass}`}>
            <div style={{ display: 'none' }}>MachID: {matchId}</div>
            <div>
                <span className="matchTableTeamName sectionText middleText">{name}</span>
                <span className="matchTableTeamResult sectionText">{winText}</span>
                <span className="matchTableTeamPlacement sectionText">place: {teamPlacement}.</span>
            </div>
        </div>
    );
};

export default TeamNamePlacement;
