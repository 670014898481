/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MatchTable from './match-table/MatchTable';
import { MatchHistory } from '../rest/match/dto/MatchHistory';
import { loadMatchHistory } from '../rest/match/MatchHistoryRest';
import { loadAndSetData } from '../util/dataLoader';
import { useAsyncError } from '../error/ErrorBoundary';
import { MODE_SHOWN_NAME_AND_ICON } from '../util/matchConstants';

const MatchList: React.FC = () => {
    const [matchHistory, setMatchHistory] = useState<MatchHistory>();

    const location = useLocation();
    const urlSearchParam = new URLSearchParams(location.search);

    const limitValue = urlSearchParam.get('limit');
    const limit = limitValue ? parseInt(limitValue, 10) : 15;

    const verboseValue = urlSearchParam.get('verbose');
    const verbose = verboseValue ? verboseValue === 'true' : false;

    const minPlacementValue = urlSearchParam.get('minPlacement');
    const minPlacement = minPlacementValue ? parseInt(minPlacementValue, 10) : undefined;

    const minKnownPlayersValue = urlSearchParam.get('minKnownPlayersCount');
    const minKnownPlayers = minKnownPlayersValue ? parseInt(minKnownPlayersValue, 10) : 1;

    let modes: string[] | undefined;
    const modeGroup = urlSearchParam.get('group');
    if (modeGroup) {
        modes = [];
        MODE_SHOWN_NAME_AND_ICON.forEach((value: Array<string>, key: string) => {
            if (value[2] === modeGroup) {
                modes?.push(key);
            }
        });
    } else {
        const modesValue = urlSearchParam.get('modes');
        modes = modesValue ? [modesValue] : undefined;
    }

    const matchIdsValue = urlSearchParam.get('matchIds');
    const matchIds: number[] = matchIdsValue ? JSON.parse(`[${matchIdsValue}]`) : undefined;
    console.log(`matchIdsValue = ${matchIds}`);

    const throwError = useAsyncError();

    const loadHistory = (): Promise<MatchHistory> => {
        return loadMatchHistory(modes, minKnownPlayers, minPlacement, limit, matchIds);
    };

    useEffect(() => {
        let isMounted = true;

        // load data required by widgets into state variables in this side effect
        loadAndSetData(loadHistory, setMatchHistory, throwError, isMounted).then(() => {});

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div>
            {matchHistory?.matchDetails.map((matchDetail) => (
                <MatchTable
                    key={matchDetail.matchId}
                    matchDetail={matchDetail}
                    verboseTeams={false}
                    verboseOurTeam={true}
                    verboseBigTeam={verbose}
                    onlyFirstPlaces={false}
                />
            ))}
        </div>
    );
};

export default MatchList;
