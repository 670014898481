import React, { ErrorInfo, ReactNode } from 'react';
import { GeneralErrorPage } from './GeneralErrorPage';

type ErrorProps = {
    children: React.ReactNode;
};

export interface ErrorState {
    hasError: boolean;
    error?: Error;
    info?: ErrorInfo;
    errorId?: string;
}

export type ErrorCallbackType = (error: Error) => void;

/**
 * useAsyncError transforms async exception into 'sync' ones to be able to catch them in error boundaries
 */
export const useAsyncError = (): ErrorCallbackType => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorCallback, setErrorCallback] = React.useState<ErrorCallbackType>();

    return React.useCallback<ErrorCallbackType>((e) => {
        setErrorCallback(() => {
            throw e;
        });
    }, []);
};

/**
 * Error boundary component catching exceptions. For now it's designed to wrap the top level of react application because
 * it shows general "Not working" error message in GeneralError component.
 */
export class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(): { hasError: boolean } {
        return {
            hasError: true,
        };
    }

    componentDidCatch = (error: Error, info: ErrorInfo): void => {
        // error during rendering was catched, generate error id
        const errorId = `ER_${new Date().valueOf().toString()}`;

        this.setState({ error, info, errorId });

        // log the error
        console.log(`${errorId} - ${error.message}`);
    };

    render(): ReactNode {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { hasError, error, info, errorId } = this.state;
        const { children } = this.props;

        return hasError ? <GeneralErrorPage errorId={errorId || ''} /> : children;
    }
}
